export const inputFormatters = {
    methods: {
        formatPercentage(value) {
            if (
                value &&
                /^[0-9]*[.]?[0-9]*$/.test(value) &&
                value[value.length] !== '%'
            ) {
                return value + '%';
            }
            return value;
        },
        format2Characters(value) {
            return String(value).substring(value.length - 2, value.length);
        },
        format4Characters(value) {
            return String(value).substring(0, 4);
        }
    }
};
