<template>
  <b-alert :show="true"
           class="pt-30 mt-30 pl-30 pr-48 d-flex justify-content-between"
           variant="alert alert-card alert-danger">
    <div v-for="(error, index) in errors" :key="'error' + index">
      <p>{{ error }}</p>
    </div>
  </b-alert>
</template>

<script>
export default {
  name: 'PaymentError',
  props: {
    errors: {
      type: Array,
      required: true
    }
  }
}
</script>
