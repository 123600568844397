<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Storage</h3>

    <StorageYN v-model="form.storage" @input="handleStorageProduct"/>

    <template v-if="form.storage">
      <div class="question-box with-arrow">

        <b-alert v-if="existingStorageProduct" show
                 variant="warning">
          <h5>Bank Details</h5>
          We already have you bank account details on file, to request a change to these please resubmit this
          form.<br><br>
          <p>Sort Code: {{ form.storage_product.existing_sort_code }}<br>
            Account Number: {{ form.storage_product.existing_bank_number }}
          </p>
          <b-button @click="show.form=!show.form" variant="link">{{ show.form ? 'Cancel' : 'Resubmit Bank Details' }}
          </b-button>
        </b-alert>

        <template v-if="show.form && form.storage_product">
          <AccountName
              v-model="form.storage_product.account_name"
              :id="form.storage_product.id"
              form-path="storage_product.account_name"
          />
          <SortCode
              v-model="form.storage_product.sort_code"
              :id="form.storage_product.id"
              form-path="storage_product.sort_code"
          />
          <AccountNumber
              v-model="form.storage_product.bank_number"
              :id="form.storage_product.id"
              form-path="storage_product.bank_number"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import {httpQuestionnaire} from "@/services";
import {fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";

import StorageYN from "@/components/common/questionnaires/question/questions/transactionCheckout/StorageYN";
import SortCode from "@/components/common/questionnaires/question/questions/storage/SortCode";
import AccountName from "@/components/common/questionnaires/question/questions/storage/AccountName";
import AccountNumber from "@/components/common/questionnaires/question/questions/storage/AccountNumber";


export default {
  name: 'Storage',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    AccountName,
    SortCode,
    AccountNumber,
    StorageYN
  },
  data() {
    return {
      label: 'Storage', // used for steps progress
      show: {
        form: false
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    existingStorageProduct() {
      if (this.form && this.form.storage_product) {
        return this.form.storage_product.existing_sort_code && this.form.storage_product.existing_bank_number
      }
      return false
    }
  },
  methods: {
    handleStorageProduct() {
      console.log('handle storage product')
      if (this.form.storage && !this.form.storage_product) {
        this.show.form = true
        // client wants a storage product
        // create storage product
        let data = {
          client: this.$store.getters.user.is_client.id
        }
        httpQuestionnaire.post('product_storage', data).then(
            response => {
              this.form.storage_product = response.data
            }
        ).catch(
            error => {
              console.log(error)
            }
        )
      } else if (this.form.storage && this.form.storage_product) {
        this.show.form = false
      }
    }
  }
};
</script>
