<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Pay</h3>

    <template>

      <PaymentError
          v-if="errors.non_field_errors && errors.non_field_errors.length"
          :errors="errors.non_field_errors"/>

      <CreditCardForm
          v-if="!show.success"
          v-model="form"
          :errors="errors"/>

      <PaymentSuccess
          v-if="show.success && show.orderId"
          :order-id="show.orderId"
      />
    </template>

    <b-button @click="pay">Pay</b-button>
  </div>
</template>

<script>
import { fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";
import { paymentHelpers } from "@/views/questionnaires/Payment/paymentHelpers";

import PaymentError from "@/views/introducer/transaction/detail/payments/PaymentError";
import CreditCardForm from "@/views/introducer/transaction/detail/payments/CreditCardForm";
import PaymentSuccess from "@/views/introducer/transaction/detail/payments/PaymentSuccess";

export default {
  name: 'Pay',
  mixins: [fieldsetHelpers, paymentHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    PaymentSuccess,
    CreditCardForm,
    PaymentError,

  },
  data() {
    return {
      label: 'Pay', // used for steps progress
      errors: {
        number: [],
        expiry_month: [],
        expiry_year: [],
        security_code: [],
        full_name: []
      },
      show: {
        loading: false,
        success: false,
        orderId: null,
        authenticate: false
      },
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
