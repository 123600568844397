<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Advice</h3>

    <AdviceYN v-model="form.advised_service" />

    <Appointment
        v-if="form.advised_service"
        v-model="form.appointment"
        :transaction="form.transaction"
        appointmentType="Estate Planning Consultation"
        host-role="Estate Planning Introducer"
    />

  </div>
</template>

<script>
import {fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";
import AdviceYN from "@/components/common/questionnaires/question/questions/transactionCheckout/AdviceYN";
import Appointment from "@/components/common/questionnaires/question/questions/appointment/Appointment";

export default {
  name: 'Advice',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {Appointment, AdviceYN},
  data() {
    return {
      label: 'Advice', // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
