<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <b-row id="appointment" class="booking-form">
      <b-col>
        <b-form
            @reset="onReset">

          <meeting-type
              v-if="role"
              @setRole="setRole"
              v-model="form"/>

          <CalendarMonth
              v-if="show.form && !show.error"
              :booking="booking"
              :durationInMinutes="form.duration"
              :inProgress="inProgress"
              :kind="form.type"
              :oneTwoThree="1"
              @currentMonth="currentMonth"
              @nextMonth="nextMonth"
              @prevMonth="prevMonth"
              @setSelected="setSelected"
              ref="calendarMonth"
          />

          <MeetingConfirmationPanel
              v-if="show.confirmation && !show.error"
              v-model="form"
              :show-reschedule="true"
              :show-steps="false"
              @reschedule="rebook"
              @createIcs="createIcs"
              @navToEvent="navToEvent"
              :step="step"
          />
          <b-button
              v-if="show.form && !show.confirmation && form.eventKey"
              @click="cancelRebook" variant="link">
            Cancel ReSchedule
          </b-button>
          <b-alert show variant="danger" v-if="show.error">
            Error Occurred: {{ show.errorMessage }}
          </b-alert>

        </b-form>
      </b-col>
    </b-row>
  </QuestionBase>
</template>

<script>
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import {helpers} from "@/components/common/appointment/calender/helpers";
import {options} from "@/components/common/appointment/calender/options";
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import MeetingConfirmationPanel from "@/components/common/appointment/calender/MeetingConfirmationPanel";
import MeetingType from "@/components/common/appointment/calender/MeetingType";
import CalendarMonth from "@/components/common/appointment/calender/CalendarMonth";

export default {
  name: "Appointment",
  mixins: [helpers, options, questionLoadHelpers],
  components: {
    QuestionBase,
    MeetingType,
    MeetingConfirmationPanel,
    CalendarMonth,
  },
  props: {
    transaction: {
      type: Object,
      required: false,
    },
    appointmentType: {
      type: String,
      required: true,
    },
    hostRole: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: false,
    },
    event: {
      type: Object,
      required: false,
    },
  },
  async mounted() {
    // set appointment type
    this.form.type = this.appointmentType;
    // set host role
    this.form.role = this.hostRole;
    if (this.data?.eventKey) {
      // used to transfer event from questionnaire form data to form; this will then fetch event details again
      console.log('has event key')
      this.form.eventKey = this.data.eventKey
      let result = await this.initEvent();
      if (result) {
        this.show.confirmation = true
        this.show.form = false
        this.form.submited = true
      }
    }
    this.shown()
  },
  watch: {
    transaction: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) this.setTransaction()
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    eventHostRole() {
      const item = this.roleByEventType.find(r => r.types.includes(this.form.type));
      if (item) {
        return item.role;
      }
      return 'Estate Planner Full'
    },
    user() {
      return this.$store.getters.user
    }
  },
  data() {
    return {
      question: {
        title: 'Please book an appointment',
        subTitle: 'You will need to book an appointment.',
        tip: null,
      },
      db: {
        saveLocation: 'transaction_checkout',
        saveField: 'appointment',
        // validationField: 'eventKey', // used for progress
        ignoreForm: true // ignores this.form as used by booking helpers in navigation helpers
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessage: "Book an appointment before proceeding"
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,

      // other
      role: null,
      initialSteps: 2,
      form: {
        location: null,
        type: null,
        transactionType: null,
        description: null,
        short: null,
        duration: null,
        ids: null,
        eventKey: null,
      },
      appointmentsRequested: {
        startTime: null,
        host: "",
      },
      show: {
        form: true,
        error: false,
        errorMessage: false,
        confirmation: false,
      },
    };
  },
  methods: {
    shown() {
      if (this.event) {
        this.form.eventKey = this.event.eventKey;
        this.form.type = this.event.type;
        this.form.role = this.eventHostRole;

        // (CB) setting event host for re-scheduling
        this.appointmentsRequested.host = this.event.owner.name;
      }
      if (this.event?.clients) {
        this.form.ids = this.event.clients.map((c) => c.id).join(",");
        this.form.names = this.event.clients
            .map((c) => c.profileNameLast)
            .join(",");
      } else if (this.transaction?.clients) {
        this.form.ids = this.transaction.clients.map((c) => c.id).join(",");
        this.form.names = this.transaction.clients
            .map((c) => c.profileNameLast)
            .join(",");
      } else if (this.user?.is_client) {
        this.form.ids = String(this.user.is_client.id)
        this.form.names = this.user.is_client.profileNameLast
      }

      this.getSlots()
    },
    clear() {
      this.$emit("refresh");
      this.show.form = true;
      this.show.confirmation = false;
      this.show.error = false
      this.show.errorMessage = null
    },
    rebook() {
      this.show.form = true;
      this.show.confirmation = false;
    },
    cancelRebook() {
      this.show.form = false;
      this.show.confirmation = true;
    },
    showConfirmation() {
      this.show.confirmation = true;
      this.show.form = false;
      // set questionnaire data for this question
      this.data = {
        id: this.form.id,
        eventKey: this.form.eventKey
      }
    },
    setTransaction() {
      // set client ids to event
      if (this.transaction) {
        this.form.ids = this.transaction.clients.map((c) => c.id).join(",");
        this.form.names = this.transaction.clients
            .map((c) => c.profileNameLast)
            .join(",");
      }
    }
  },
};
</script>
