<template>
  <QuestionnaireBase
      :title="title"
      :form="form"
      :finishFunction="null"
      :showNavigationButtons="true"
  >
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "@/views/questionnaires/loadHelpers";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import Advice from "@/components/common/questionnaires/fieldsets/transactionCheckout/Advice";
import Storage from "@/components/common/questionnaires/fieldsets/transactionCheckout/Storage";
import Printing from "@/components/common/questionnaires/fieldsets/transactionCheckout/Printing";
import Pay from "@/components/common/questionnaires/fieldsets/transactionCheckout/Pay";

export default {
  name: "Checkout",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    Advice,
    Storage,
    Printing,
    Pay
  },
  data() {
    return {
      name: 'Checkout',
      title: "Checkout",
      url: 'transaction_checkout',
      order: [ // imported components required here to load
          'Advice',
          'Storage',
          'Printing',
          'Pay'
      ]
    };
  }
};
</script>
