<template>
  <b-alert :show="true"
           class="pt-30 mt-30 pl-30 pr-48 d-flex justify-content-between"
           variant="alert alert-card alert-success">
    <div>
      <h3>Payment Confirmed</h3>
      <p>Order number: {{ orderId }}</p>
    </div>
  </b-alert>
</template>

<script>
export default {
  name: 'PaymentSuccess',
  props: {
    orderId: {
      type: String,
      required: true
    }
  }
}
</script>
