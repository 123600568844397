<template>
  <div class="questionnaire">

    <b-row>
      <b-col cols="8">
        <QuestionBase
          :errors="[...errors.number]"
          :question="question.number"
          :valid="!!form.number"
        >
          <InputStandardBasic
            v-model="form.number"
            :args="inputArgs.number"
            :errors="errors.number"
          />
        </QuestionBase>
      </b-col>
      <b-col cols="4">
        <QuestionBase
          :errors="[...errors.security_code]"
          :question="question.security_code"
          :valid="!!form.security_code"
        >
          <InputStandardBasic
            v-model="form.security_code"
            :args="inputArgs.security_code"
            :errors="errors.security_code"
          />
        </QuestionBase>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col cols="12">
            <QuestionBase
                :errors="[...errors.full_name]"
                :question="question.full_name"
                :valid="!!form.full_name"
            >
              <InputStandardBasic
                  v-model="form.full_name"
                  :args="inputArgs.full_name"
                  :errors="errors.full_name"
              />
            </QuestionBase>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="d-flex justify-content-between" cols="4">
        <QuestionBase
          :errors="[...errors.expiry_month]"
          :question="question.expiry_month"
          :valid="!!form.expiry_month"
          class="pb-0"
        >
          <InputStandardBasic
            v-model="form.expiry_month"
            :args="inputArgs.expiry_month"
            :errors="errors.expiry_month"
          />
        </QuestionBase>
        <QuestionBase
          :errors="[...errors.expiry_year]"
          :question="question.expiry_year"
          :valid="!!form.expiry_year"
          class="pb-0"
        >
          <InputStandardBasic
            v-model="form.expiry_year"
            :args="inputArgs.expiry_year"
            :errors="errors.expiry_year"
          />
        </QuestionBase>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col cols="12">
            <QuestionBase
                :errors="[...errors.address_line_one]"
                :question="question.address_line_one"
                :valid="!!form.address_line_one"
            >
              <InputStandardBasic
                  v-model="form.address_line_one"
                  :args="inputArgs.address_line_one"
                  :errors="errors.address_line_one"
              />
            </QuestionBase>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="8">
        <b-row>
          <b-col cols="12">
            <QuestionBase
                :errors="[...errors.address_line_two]"
                :question="question.address_line_two"
                :valid="!!form.address_line_two"
            >
              <InputStandardBasic
                  v-model="form.address_line_two"
                  :args="inputArgs.address_line_two"
                  :errors="errors.address_line_two"
              />
            </QuestionBase>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
        <b-col cols="4">
          <QuestionBase
              :errors="[...errors.city]"
              :question="question.city"
              :valid="!!form.city"
          >
            <InputStandardBasic
                v-model="form.city"
                :args="inputArgs.city"
                :errors="errors.city"
            />
          </QuestionBase>
        </b-col>
      <b-col cols="4">
        <QuestionBase
            :errors="[...errors.post_code]"
            :question="question.post_code"
            :valid="!!form.post_code"
        >
          <InputStandardBasic
              v-model="form.post_code"
              :args="inputArgs.post_code"
              :errors="errors.post_code"
          />
        </QuestionBase>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { inputFormatters } from "@/mixins/inputFormatters";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputStandardBasic from "@/components/common/other/inputs/InputStandardBasic";

export default {
  name: "CreditCardForm",
  components: {
    InputStandardBasic,
    QuestionBase,
  },
  mixins: [inputFormatters],
  props: {
    value: {
      type: Object,
      required: false,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
  },
  data() {
    return {
      question: {
        number: {
          title: null,
          subTitle: null,
          tip: null,
        },
        expiry_month: {
          title: null,
          subTitle: null,
          tip: null,
        },
        expiry_year: {
          title: null,
          subTitle: null,
          tip: null,
        },
        security_code: {
          title: null,
          subTitle: null,
          tip: null,
        },
        full_name: {
          title: null,
          subTitle: null,
          tip: null,
        },
        address_line_one: {
          title: null,
          subTitle: null,
          tip: null,
        },
        address_line_two: {
          title: null,
          subTitle: null,
          tip: null,
        },
        post_code: {
          title: null,
          subTitle: null,
          tip: null,
        },
        city: {
          title: null,
          subTitle: null,
          tip: null,
        },
        date_of_birth: {
          title: null,
          subTitle: null,
          tip: null,
        }

      },
      inputArgs: {
        number: {
          placeholder: "e.g. 1111 2222 3333 4444",
          inputClass: "field-100",
          label: "Card Number",
        },
        expiry_month: {
          placeholder: "e.g. 01",
          inputClass: "field-100",
          label: "Expiry Month",
          formatter: this.format2Characters,
        },
        expiry_year: {
          placeholder: "e.g. 23",
          inputClass: "field-100",
          label: "Expiry Year",
          formatter: this.format2Characters,
        },
        security_code: {
          placeholder: "e.g. 001",
          inputClass: "field-100",
          label: "Security Code (CVV)",
        },
        full_name: {
          placeholder: "e.g. Jo Smith",
          inputClass: "field-100",
          label: "Cardholders Full Name",
        },
        address_line_one: {
          placeholder: "e.g. The Old Rectory",
          inputClass: "field-100",
          label: "Address Line 1",
        },
        address_line_two: {
          placeholder: "e.g. Dark Lane",
          inputClass: "field-100",
          label: "Address Line 2",
        },
        city: {
          placeholder: "e.g. Wolverhampton",
          inputClass: "field-100",
          label: "Address City",
        },
        post_code: {
          placeholder: "e.g. WV109PG",
          inputClass: "field-100",
          label: "Address Post code",
        },
        date_of_birth: {
          placeholder: "e.g. Dark Lane",
          inputClass: "field-100",
          label: "Address Line 2",
        },

      },
    };
  },
};
</script>
