<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Printing</h3>

    <PrintingYN v-model="form.printing" />
  </div>
</template>

<script>
import { httpQuestionnaire } from "@/services";
import { fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";
import PrintingYN from "@/components/common/questionnaires/question/questions/transactionCheckout/PrintingYN";

export default {
  name: 'Printing',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    PrintingYN
  },
  data() {
    return {
      label: 'Printing', // used for steps progress
      navigateNextFunction: this.syncInvoice
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    async syncInvoice () {
      // create or update invoice for checkout
      console.log('sync invoice')
      let data = {
        transaction_checkout: this.form.id // transaction checkout object
      }
      return httpQuestionnaire.post('transaction_checkout_invoice', data).then(
          response => {
            console.log(response.data)
            return true
          }
      ).catch(
          error => {
            console.log(error)
            return false
          }
      )
    }
  }
};
</script>
